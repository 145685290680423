<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
//import axios from "axios";


/**
 * User list component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Coupons",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "User List",
      items: [
        {
          text: "Coupons",
        },
        {
          text: "Coupons List",
          active: true,
        },
      ],
      couponList: [],
      totalRows: 1,
      currentPage: 1,
      lastVisible: null,
      perPage: 500,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      loadingCoupons: false,
      endLoadMore: false,
      searchingCoupon: false,
      limit: 2000,
      searchFilter: "all",
      searchFilterOptions: ["all","used", "not used"],
      listFilter: "all",
      listFilterOptions: ["all","used", "not used"],
      sortBy: "code",
      sortDesc: false,
      fields: [
        {
          key: "code",
        },
        {
          key: "date_created",
        },
        {
          key: "used",
        },
        {
          key: "date_used",
        },
        {
          key: "mail_used",
        },
        {
          key: "iduser_used",
        },
      ]
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return 1;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getCoupons();
  },
  methods: {
    
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetSearch() {
      this.couponList = [];
      this.lastVisible = null;
      this.endLoadMore = false;
      this.getCoupons();
    },
    searchCoupons() {
      var search = document.getElementById("search").value;

      if (search != "" && search != null) {
        this.couponList = [];
        this.lastVisible = null;
        this.endLoadMore = true;
        this.searchingCoupon = true;
        var db = firebase.firestore();
        var requete = db.collection("coupons").orderBy("code", "asc");

        requete
          .get()
          .then((coupons) => {
            coupons.forEach(async (coupon) => {
              this.couponList.push({
                id: coupon.id,
                data: coupon.data(),
              });
              this.searchingCoupon = false;
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      }
    },
    getCoupons() {
      this.loadingCoupons = true;
      var db = firebase.firestore();
      var requete = db.collection("coupons").orderBy("code", "asc");
      if (this.lastVisible != null) {
        requete = requete.startAfter(this.lastVisible);
      }

      if (this.listFilter != "all" && this.listFilter == "used")
        requete = requete.where("used", "==", true);
      else if (this.listFilter != "all" && this.listFilter == "not used")
        requete = requete.where("used", "==", false);

      requete
        .limit(this.limit)
        .get()
        .then((coupons) => {
          console.log("nb coupons: " + coupons.docs.length);
          this.lastVisible = coupons.docs[coupons.docs.length - 1];
          if (coupons.docs.length == 0 || coupons.docs.length < this.limit) {
            this.endLoadMore = true;
          }
          coupons.forEach(async (coupon) => {
            this.couponList.push({
              id: coupon.id,
              data: coupon.data(),
            });
            this.loadingCoupons = false;
          });
          //document.getElementById("savemodifications").style.display = "none";
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            
            <div class="row mt-4 mb-4" v-if="JSON.parse(isAdmin)">
              <div class="col-auto">
                <b-form-select
                  @change="resetSearch()"
                  v-model="listFilter"
                  :options="listFilterOptions"
                ></b-form-select>
              </div>
              <!-- Search -->
              <div class="col-auto align-end">
                <div class="row">
                  <div class="col-auto">Search :</div>
                  <div class="col-auto">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="search"
                      placeholder="N° Code"
                    />
                  </div>
                  <div class="col-auto">
                    <button
                      type="button"
                      class="btn btn-success btn-sm"
                      @click="searchCoupons()"
                    >
                      Go
                    </button>
                  </div>
                  <div class="col-auto">
                    <button type="button" class="btn btn-danger btn-sm" @click="resetSearch()">
                      Reset
                    </button>
                  </div>
                  <div class="col-auto">
                    Total: {{ couponList.length }}
                    </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                class="table table-centered table-nowrap"
                :items="couponList"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(code)="data">
                  <span>{{ data.item.data.code }}</span>
                </template>

                <template v-slot:cell(date_created)="data">
                  <span>{{ Date(data.item.data.date_created) }}</span>
                </template>

                <template v-slot:cell(used)="data">
                  <span>{{ data.item.data.used }}</span>
                </template>

                <template v-slot:cell(date_used)="data">
                  <span>{{ data.item.data.date_used }}</span>
                </template>

                <template v-slot:cell(mail_used)="data">
                  <span>{{ data.item.data.mail_used }}</span>
                </template>

                <template v-slot:cell(iduser_used)="data">
                  <span>{{ data.item.data.iduser_used }}</span>
                </template>
                
                
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-primary w-md waves-effect waves-light"
                  @click="getCoupons()"
                  v-if="!this.endLoadMore"
                >
                  <span
                    v-if="this.loadingCoupons"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="d-none d-sm-inline-block me-2"
                    >Load More Coupons</span
                  >
                  <i class="mdi mdi-send float-end"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
